
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    name: { type: String },
    email: { type: String },
  },
  setup() {
    return {}
  },
})
