
import { computed, defineComponent, ref, onMounted, watch, reactive } from 'vue'
import { formatTime, shortcutsDatetimePicker } from '@/utils/dateTime'
import { formatterDollars } from '@/utils/formatter'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import Pagination from '@/components/common/Pagination.vue'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import useOrderItemRepositories from '@/repositories/useOrderItemRepositories'
import { statusListAgency, statusTitleMap } from '@/utils/constants'
import useShopRepositories from '@/repositories/useShopRepositories'
import moment from 'moment'
import { getOrderStatusColor, isManagerOrAccounting } from '@/utils/orderUtils'
import { imageUrl } from '@/utils/image'
import useSellerTeamRepositories from '@/repositories/useSellerTeamRepositories'
import { CloseBold } from '@element-plus/icons'
import OrderSourceTag from '@/components/orders/display/OrderSourceTag.vue'
import CustomerInfoCell from '@/components/orders/display/CustomerInfoCell.vue'
import ProxyDomainPopover from '@/components/orders/display/ProxyDomainPopover.vue'
import SellerTeamInfoCell from '@/components/orders/display/SellerTeamInfoCell.vue'
import IsUseLattePaymentTag from '@/components/orders/display/IsUseLattePaymentTag.vue'
import CatalogTypeByStatusTag from '@/components/orders/display/CatalogTypeByStatusTag.vue'
import ImportFileDialog from '@/components/common/ImportFileDialog.vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotification } from '@/composables'
import { ElMessageBox } from 'element-plus'
import { isLatteAgency } from '@/utils/agencyUtils'
import useProxyRepositories from '@/repositories/useProxyRepositories'
import { getWarningContent } from '@/utils/orderUtils'
import { Warning } from '@element-plus/icons'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Pagination,
    CloseBold,
    OrderSourceTag,
    CustomerInfoCell,
    ProxyDomainPopover,
    SellerTeamInfoCell,
    IsUseLattePaymentTag,
    CatalogTypeByStatusTag,
    ImportFileDialog,
    Warning,
  },
  setup() {
    const breadcrumbs = ['Order Items', '']
    const title = 'Order Items'
    const titleReport = 'Export'
    const titleImport = 'Import Catalog Code'
    const isExporting = ref(false)

    let loading = ref(false)
    const isOrderExporter = ref(false)
    const route = useRoute()
    const router = useRouter()

    const { error, success } = useNotification()

    const columnTemp = [
      {
        key: '_id',
        title: 'Order Name',
        dataIndex: 'name',
        minWidth: '12',
      },
      {
        title: 'FFM status',
        dataIndex: 'status',
        minWidth: '8',
      },
      {
        title: 'Seller',
        dataIndex: 'seller',
        minWidth: '10',
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        minWidth: '10',
      },
      {
        title: 'Source',
        dataIndex: 'source',
        minWidth: '7',
      },
      {
        title: 'Type',
        dataIndex: 'catalogType',
        minWidth: '10',
      },
      {
        title: 'Title',
        dataIndex: 'title',
        minWidth: '15',
      },
      {
        title: 'Image',
        dataIndex: 'image',
        minWidth: '8',
      },
      {
        title: 'Design',
        dataIndex: 'design',
        minWidth: '6',
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        minWidth: '10',
      },
      {
        title: 'Custom',
        dataIndex: 'custom',
        minWidth: '8',
      },
      {
        title: 'Variant',
        dataIndex: 'orderItemVariant',
        minWidth: '8',
      },
      {
        title: 'FFM Code',
        dataIndex: 'catalogCode',
        minWidth: '8',
      },
      {
        title: 'Export Status',
        dataIndex: 'exportStatus',
        minWidth: '6',
      },
    ]

    const columns = computed(() => {
      if (isOrderExporter.value) {
        const temp = columnTemp.filter(
          (item: any) => item.dataIndex !== 'seller'
        )
        return temp
      }
      return columnTemp
    })

    const daterange = ref([])
    let params = reactive({
      orderName: route.query.orderName || null,
      transactionId: route.query.transactionId || null,
      productTitle: route.query.productTitle || null,
      handle: route.query.handle || null,
      catalogCode: route.query.catalogCode || null,
      variantTitle: route.query.variantTitle || null,
      status: route.query.status || null,
      design: route.query.design || null,
      catalogCodeStatus: route.query.catalogCodeStatus || null,
      personalized: route.query.personalized || null,
      store: route.query.store || null,
      type: route.query.type || null,
      sellerTeam: route.query.sellerTeam || null,
      isUseLattePayment: route.query.isUseLattePayment || null,
      fromDate: route.query.fromDate || null,
      toDate: route.query.toDate || null,
      page: route.query.page || 1,
      limit: route.query.limit || 10,
    })

    const { getOrderItems, state } = useOrderRepositories()
    const { exportOrderItems, importOrderItemsForUpdateCatalog } =
      useOrderItemRepositories()

    const isAllowImportExport = ref(false)
    let userInfo = undefined
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
      isAllowImportExport.value = isManagerOrAccounting(userInfo)
    } catch (e) {
      return error("Can't find user info")
    }

    const content = computed(() => {
      const extraActions = {
        breadcrumbs,
        title,
        titleReport: isAllowImportExport.value ? titleReport : '',
        loadingReport: isExporting.value,
      }
      if (isOrderExporter.value) return extraActions
      return {
        ...extraActions,
        titleImport: isAllowImportExport.value ? titleImport : '',
      }
    })

    const listDesignFilter = [
      {
        id: 1,
        name: 'YES',
        value: '1',
      },
      {
        id: 2,
        name: 'NO',
        value: '0',
      },
    ]

    const catalogCodeStatusList = [
      {
        id: 1,
        name: 'HAVE CODE',
        value: '1',
      },
      {
        id: 2,
        name: 'NOT FOUND CODE',
        value: '0',
      },
    ]

    const listPersonalizedFilter = [
      {
        id: 1,
        name: 'Regular',
        value: '0',
      },
      {
        id: 2,
        name: 'Personalized',
        value: '1',
      },
    ]
    const listTypeFilter = computed(() => {
      let defaultTypes = [
        { id: 1, label: 'POD', value: 'pod' },
        { id: 2, label: 'DROP', value: 'drop' },
      ]
      if (isLatteAgency())
        defaultTypes.push({ id: 3, label: 'FK', value: 'fk' })
      return defaultTypes
    })
    const listStore = ref<any>([{}])
    const { getAllStores } = useShopRepositories()
    const getAllStoresSellers = async (query?: string) => {
      if (query?.trim()) {
        let stores = await getAllStores(query)
        listStore.value = stores as any[]
      }
    }

    const sellerTeams = ref<any[]>([])
    const { getAllSellerTeam } = useSellerTeamRepositories()
    const getSellerTeamList = async (query?: string) => {
      if (query?.trim()) {
        let res = await getAllSellerTeam(query)
        sellerTeams.value = res as any[]
      }
    }

    if (localStorage.getItem('isOrderExporter') !== 'true') {
      // getSellerTeamList()
      // getAllStoresSellers()
    }

    const getData = async () => {
      loading.value = true
      await getOrderItems(params)
      loading.value = false
    }
    const onChangeDate = () => {
      if (daterange.value?.length > 1) {
        let startDate = moment(daterange.value[0]).format('YYYY-MM-DD')
        let endDate = moment(daterange.value[1]).format('YYYY-MM-DD')
        params.fromDate = startDate
        params.toDate = endDate
      } else {
        params.fromDate = ''
        params.toDate = ''
      }
    }

    const showCustom = (items: any) => {
      let custom = ''
      if (items?.length > 0) {
        items.forEach((item: any) => {
          custom += `${item.name}\n`
        })
      }
      return custom || '-'
    }

    const removeNullProperties = (object: any) => {
      for (const [key, value] of Object.entries(object)) {
        if (value === null || value === undefined || value === '') {
          delete object[key]
        }
      }
      return object
    }

    const updateURL = () => {
      params = removeNullProperties(params)
      router.push({
        query: params,
      })
    }

    const fetchData = () => {
      updateURL()
      getData()
    }

    onMounted(() => {
      fetchData()
      isOrderExporter.value = localStorage.getItem('isOrderExporter') === 'true'
    })

    let emitTimeout: any = false
    watch(params, () => {
      // delay the event
      if (emitTimeout) {
        clearTimeout(emitTimeout)
      }
      emitTimeout = setTimeout(() => {
        fetchData()
      }, 500)
    })

    const downloadFile = (url: any) => {
      const path = url.split('/')
      const fileName = path[path.length - 1]
      const aLink = document.createElement('a')
      aLink.href = url
      aLink.download = fileName
      const event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }

    const exportItemCatalogCode = async () => {
      ElMessageBox.confirm(
        `Confirm to export ${state.total} items?`,
        'Warning',
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async () => {
        isExporting.value = true
        const res: any = await exportOrderItems(params)
        if (res?.status === 200 || res?.status === 201) {
          downloadFile(res?.data?.url)
        } else {
          error('Export order items fail!')
        }
        isExporting.value = false
      })
    }

    const importFileDialog = ref<InstanceType<typeof ImportFileDialog>>()
    const importOrderItemCatalogCode = () => {
      importFileDialog.value?.toggle()
    }

    const onAfterSubmitFile = async (data: any) => {
      const file = data.file
      if (!file) {
        error('File not found!')
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      const res = await importOrderItemsForUpdateCatalog(formData)

      if (res?.status === 200 || res?.status === 201) {
        success('Update success!')
        getData()
      } else {
        error('Export order items fail!')
      }
    }

    const clearFilters = () => {
      params.orderName = null
      params.transactionId = null
      params.productTitle = null
      params.handle = null
      params.catalogCode = null
      params.variantTitle = null
      params.status = null
      params.design = null
      params.catalogCodeStatus = null
      params.personalized = null
      params.store = null
      params.type = null
      params.sellerTeam = null
      params.isUseLattePayment = null
      params.fromDate = null
      params.toDate = null
      daterange.value = []
    }
    return {
      content,
      onAfterSubmitFile,
      importFileDialog,
      params,
      loading,
      columns,
      formatTime,
      onChangeDate,
      getOrderItems,
      state,
      listStore,
      listDesignFilter,
      listPersonalizedFilter,
      listTypeFilter,
      statusListAgency,
      shortcutsDatetimePicker,
      daterange,
      getOrderStatusColor,
      statusTitleMap,
      showCustom,
      sellerTeams,
      getSellerTeamList,
      imageUrl,
      catalogCodeStatusList,
      getData,
      formatterDollars,
      exportItemCatalogCode,
      importOrderItemCatalogCode,
      downloadFile,
      isExporting,
      clearFilters,
      fetchData,
      isAllowImportExport,
      isOrderExporter,
      getWarningContent,
      getAllStoresSellers,
    }
  },
})
